<template>
    <div>
        <el-form-item label="普通商品未支付取消订单时间（单位：天）" label-width="300px">
            <el-input-number size="small" v-model="num" @change="handleChange" :min="1" :max="10"
                             label="描述文字"></el-input-number>
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: "orderConfig"
}
</script>

<style scoped>

</style>
