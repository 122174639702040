<template>
    <div>

        <el-form-item label="默认上传方式">
            <el-radio v-model="storageDefault" :label="item.key" :key="item.key" v-for="item in storageLabels">{{ item.name }}</el-radio>
        </el-form-item>

        <template v-if="storageDefault === 'local'">
            <el-form-item label="本地服务器地址">
                <el-input size="small" placeholder="" v-model="formData.values.engine.local.domain"></el-input>
            </el-form-item>
        </template>

        <template v-if="storageDefault === 'qiniu'">
            <el-form-item label="访问网址：">
                <el-link type="primary" target="_blank" href="https://portal.qiniu.com/kodo/bucket">七牛云</el-link>
            </el-form-item>

            <el-form-item label="bucket：">
                <el-input size="small" placeholder="" v-model="formData.values.engine.qiniu.bucket"></el-input>
            </el-form-item>
<!--            <el-form-item label="空间域名Domain">-->
<!--                <el-input size="small" placeholder="" v-model="formData.values.engine.qiniu.domain"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="accessKey：">
                <el-input size="small" placeholder="" v-model="formData.values.engine.qiniu.access_key"></el-input>
            </el-form-item>
            <el-form-item label="secretKey：">
                <el-input size="small" placeholder="" v-model="formData.values.engine.qiniu.secret_key"></el-input>
            </el-form-item>
            <el-form-item label="上传地址：">
                <el-input size="small" placeholder="" v-model="formData.values.engine.qiniu.qiniu_url"></el-input>
            </el-form-item>
            <el-form-item label="访问前缀：">
                <el-input size="small" placeholder="" v-model="formData.values.engine.qiniu.qiniu_addr"></el-input>
            </el-form-item>
        </template>

        <template v-if="storageDefault === 'aliyun'">
            <el-form-item label="存储空间bucket">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.bucket"></el-input>
            </el-form-item>
            <el-form-item label="空间域名Domain">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.domain"></el-input>
            </el-form-item>
            <el-form-item label="accessKey">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.access_key"></el-input>
            </el-form-item>
            <el-form-item label="secretKey">
                <el-input size="small" placeholder="" v-model="formData.values.engine.aliyun.secret_key"></el-input>
            </el-form-item>
        </template>

        <submit-btn @settingConfigData="settingConfigData"></submit-btn>
    </div>
</template>

<script>
import submitBtn from "@/views/systemManage/config/components/submitBtn.vue";
import {getSettingConfigApi, settingConfigApi} from "@/views/systemManage/config/api";

export default {
    name: "ossConfig",
    components: {submitBtn},

    data() {
        return {
            storageDefault: '',

            storageLabels: [
                {name: '七牛云', key: 'qiniu'},
                {name: '本地', key: 'local'},
                // {name: '阿里云', key: 'aliyun'},
            ],

            formData: {
                key: 'storage',
                describe: '上传方式',
                values: {
                    default: 'local',
                    engine: {
                        local: {},
                        qiniu: {},
                        aliyun: {}
                    }
                }
            },
        }
    },

    mounted() {
        getSettingConfigApi(this.formData.key).then(res => {
            if (res.values) {
                this.formData.values = res.values || {}
            }

            this.storageDefault = res.values.default || 'local'
        })
    },

    methods: {
        settingConfigData() {
            settingConfigApi(this.formData).then(() => {
                this.$message.success('更新成功')
            })
        },
    }
}
</script>

<style scoped>

</style>
