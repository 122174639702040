<template>
    <el-form-item label="" label-width="0px">
        <el-button type="primary" size="small" @click="submit">提交</el-button>
        <el-button type="" size="small">取消</el-button>
    </el-form-item>
</template>

<script>
export default {
    name: "submitBtn",

    methods: {
        submit() {
            this.$emit('settingConfigData')
        }
    }
}
</script>

<style scoped>

</style>
