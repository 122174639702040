<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="config.name" :name="config.id" :key="config.id" v-for="config in configLabels" />
    </el-tabs>
    <el-form ref="form" :model="formData" label-width="120px">
      <!-- logo设置 -->
      <website-config v-if="activeName == 'website'"></website-config>

      <!-- 订单设置 -->
      <order-config v-if="activeName == 'order'"></order-config>

      <!-- 物流设置 -->
      <express-config v-if="activeName == 'express'"></express-config>

      <!-- 存储设置 -->
      <oss-config v-if="activeName == 'oss'"></oss-config>

      <!-- 短信设置 -->
      <sms-config v-if="activeName == 'sms'"></sms-config>

      <!-- 公众号  -->
      <official-list v-if="activeName == 'official'"></official-list>

      <!--            <el-form-item label="" label-width="0px">-->
      <!--                <el-button type="primary" size="small">提交</el-button>-->
      <!--                <el-button type="" size="small">取消</el-button>-->
      <!--            </el-form-item>-->
    </el-form>
  </div>
</template>

<script>
import websiteConfig from './components/websiteConfig.vue'
import smsConfig from './components/smsConfig.vue'
import ossConfig from './components/ossConfig.vue'
import expressConfig from './components/expressConfig.vue'
import orderConfig from './components/orderConfig.vue'
import OfficialList from "@/views/systemManage/config/components/officialList.vue";

export default {
  name: 'List',

  data() {
    return {
      configLabels: [
        // { id: 'website', name: '站点设置' },
        // {id: 'order', name: '订单设置'},
        // {id: 'express', name: '物流设置'},
        { id: 'oss', name: '存储设置' },
        // { id: 'official', name: '公众号配置' },
        {id: 'sms', name: '短信设置'},
      ],

      activeName: 'oss',
      formData: {
        express: '100'
      }
    }
  },

  mounted() {},

  methods: {},

  components: {OfficialList, websiteConfig, smsConfig, ossConfig, expressConfig, orderConfig }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form {
    .el-form-item {
      .el-input {
        width: 500px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }

  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
}
</style>
