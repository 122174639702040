<template>
    <div>
        <el-form-item label="接口选择" label-width="100px">
            <el-radio v-model="formData.express" label="100">快递100</el-radio>
        </el-form-item>
        <el-form-item label="快递查询密钥" label-width="100px">
            <el-input size="small" placeholder=""></el-input>
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: "officialList",

    data() {
        return {
            formData: {

            }
        }
    }
}
</script>

<style scoped>

</style>
