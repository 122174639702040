<template>
    <div>
        <el-form-item label="站点名称" label-width="100px">
            <el-input size="small" placeholder="" v-model="formData.values.siteName"></el-input>
        </el-form-item>
        <el-form-item label="站点logo" label-width="100px">
            <file-upload-img :url.sync="formData.values.logo"></file-upload-img>
        </el-form-item>
        <el-form-item label="登录背景图" label-width="100px">
          <file-upload-img  :url.sync="formData.values.loginBg"></file-upload-img>
        </el-form-item>
        <submit-btn @settingConfigData="settingConfigData"></submit-btn>
    </div>
</template>

<script>

import submitBtn from "./submitBtn.vue";
import {settingConfigApi, getSettingConfigApi} from '../api'
import Uploader from "@/components/uploader/index.vue";
import FileUploadImg from "@/components/uploader/uploader-img.vue";

export default {
    name: "websiteConfig",
    components: {FileUploadImg, submitBtn},
    data() {
        return {
            formData: {
                key: 'site_configuration',
                describe: '站点设置',
                values: {
                    siteName: '',
                    logo: '',
                    loginBg: '',
                }
            }
        }
    },

    mounted() {
        getSettingConfigApi(this.formData.key).then(res => {
            this.formData.values = res.values || {}
        })
    },

    methods: {
        uploadLogo({fileUrl}) {
            this.formData.values.logo = fileUrl
        },
        uploadBg({fileUrl}) {
            this.formData.values.loginBg = fileUrl
        },
        settingConfigData() {
            settingConfigApi(this.formData).then(() => {
                this.$message.success('更新成功')
            })
        },
    }
}
</script>

<style scoped>

</style>
