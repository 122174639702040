import { render, staticRenderFns } from "./ossConfig.vue?vue&type=template&id=3b4a5646&scoped=true&"
import script from "./ossConfig.vue?vue&type=script&lang=js&"
export * from "./ossConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b4a5646",
  null
  
)

export default component.exports